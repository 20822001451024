<template>
  <div class="number_recard">
    <!-- 操作按钮 -->
    <div class="number_recard_handles">
      <div 
        class="number_recard_handles_item" 
        v-for="(button, index) in handleButtons" 
        @click="handleEvent(button.eventName)"
        :key="index">
        <text class="number_recard_handles_item_label">
          {{button.label}}
        </text>
      </div>
    </div>
    <!-- 投注单 -->
    <div class="number_recard_betNumbers">
      <div class="number_recard_betNumbers_items">
        <div 
          class="number_recard_betNumbers_items_item" 
          v-for="(betNumber, index) in betBall" 
          :key="index">
          <span class="lottery_delete" @click="deleteNumber(index)"></span>
          <text>{{label}}</text>
          <div class="balls_content">
            <text class="red" v-for="(redBallValue, redIndex) in betNumber.redBalls" :key="index+redBallValue+redIndex">{{redBallValue}}</text>
            <text class="blue" v-for="(blueBallValue, redIndex) in betNumber.blueBalls" :key="index+blueBallValue+redIndex">{{blueBallValue}}</text>
          </div>
          <span class="lottery_modify" @click="modifyNumber(index)">修改</span>
      </div>
      </div>
    </div>
    <!-- 底部确认框 -->
    <div class="number_recard_confirm">
      <div class="number_recard_confirm_infos">
        <div class="number_recard_confirm_infos_left">
          <text>投</text>
          <div>
            <text class="number_recard_confirm_infos_left_icon icon_minus" @click="poursHandle('minus')"></text>
            <input v-model="times" @blur="inputEvent" type="text" />
            <text class="number_recard_confirm_infos_left_icon icon_plus" @click="poursHandle('plus')"></text>
          </div>
          <text>倍</text>
        </div>
        <div class="number_recard_confirm_infos_right">
          <text>{{times}}倍 </text>
          <text>{{pours}}注 </text>
          <text class="red">共{{countMoney}}元</text>
        </div>
      </div>
      <!-- 生成短信 -->
      <div class="number_recard_confirm_genmessage">
        <text>生成短信后点击发送即可完成选号！</text>
        <text class="red_white_solid button_middle" @click="genMessages">生成短信</text>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, inject, computed } from 'vue'
import { 
    getQueryStr, 
    jumpTo, 
    sleep, 
    toast, 
    genePourNumber, 
    addMachineArr, 
    getVisitor,
    sendMessage,
    isSevenChooseFive,} from '../utils/util'
export default {
  name: 'betNumberRecard',
  setup () {
      const state = reactive({
        handleButtons: [
          {label: '添加自选号码', eventName: 'addChooseSelf'},
          {label: '添加机选1注', eventName: 'addChooseMachine'}
        ],
        timesType: 1, // 1: 倍 2：注
        times: 1, // 倍数
        pours: 1, // 注数
      })
      const countMoney = computed(() => {
        return state.times * state.pours *2
      })
      state.pours = getQueryStr('pours') || 0
      const timesType = state.timesType == 1 ? '倍' : '注'
      const gameId = getQueryStr('gameId')
      const modeIndex = getQueryStr('modeId')

      const maxDoubleValue = state.timesType == 1 ? 
        isSevenChooseFive(gameId) ? 50 : 50 : 99

      // 当前投注方式
      // const mode = inject('chooseNumberInfo').getData(gameId)
      const currentMode = inject('chooseNumberInfo').getData(gameId).modes[modeIndex]
      // 标签名
      const label = currentMode.label
      // 可多倍
      const multiTimes = currentMode.multiTimes
      // 可多注
      const multiPours = currentMode.multiPours
       //  投注号码
      const betBall = computed(() => {
        return currentMode.betNumberArr
      })
      // 初始化注数和备注
      function initPourAndTimes() {
          let num = 0 
          currentMode.betNumberArr && currentMode.betNumberArr.forEach(ele => {
            num += ele.pour ? ele.pour : 1
          })
          state.pours = num
      }
      initPourAndTimes()
      // 最大可投注倍/注提示
      function toastTipMax(value = maxDoubleValue) {
          toast(`一次最多可投注${value}${timesType}`)
      }

      // 最小可投注倍/注数提示
      function toastTipMini(value = 1) {
          toast(`一次至少需选择${value}${timesType}`)
      }

    function setValue(value) {
        state.times = value
    }
    // 初始化球球
    function initBalls() {
        currentMode.balls.forEach(ele => {
            ele.showBalls.forEach((ball) => ball.isChoosed = false)
        })
    }
      // 输入事件
      async function inputEvent() {
            const _value = state.times
            if (!multiTimes) {
              handleTips(`只能投注一倍`)
              await sleep(1000)
              setValue(1)
              return
            }
            if (isNaN(_value)) {
              toast(`投注${timesType}只能输入数字`)
              await sleep(2000)
              setValue(1)
            } else {
              if (_value > maxDoubleValue) {
                toastTipMax()
                await sleep(1000)
                let num = _value+'0'
                const sliceNum = state.timesType == 1 ? 2 : 3
                num = num.slice(0, sliceNum)
                state.times = parseInt(num)
              } else if (_value < 1) {
                toastTipMini()
                await sleep(1000)
                setValue(1)
              }
            }
      }
      function poursHandle(eventName) {
        if(!currentMode.timesNeeded && (!multiTimes || currentMode.betNumberArr.length > 1)) {
          handleTips(`只能选择一倍`, multiTimes)
        } else {
          state.times = eventName == 'minus' ? state.times - 1 : state.times + 1
          inputEvent()
        }
      }
      // 删除已选注数
      function deleteNumber(index) { 
          currentMode.betNumberArr.splice(index, 1)
          currentMode.betNumberIndexArr.splice(index, 1)
          initPourAndTimes()
      }
      // 修改已选号码
      function modifyNumber(index) {
          jumpTo(`/choosenumber?gameId=${gameId}&showBallsIndex=${index}&modeIndex=${modeIndex}`)
      }
      // 头部操作按钮事件
      function handleEvent(eventName) {
          const betArrLen = currentMode.betNumberArr && currentMode.betNumberArr.length || 0
          if (betArrLen == 1 && !currentMode.multiPours) {
            handleTips('只能选择一注号码')
            return
          }
          if (betArrLen > 4) {
            handleTips('最多只能选择5注')
            return
          }
          if(currentMode.timesNeeded || (multiPours && state.times < 2) || betArrLen < 1) {
             if (eventName == 'addChooseSelf') {
               initBalls()
               jumpTo(`/choosenumber?gameId=${gameId}&modeIndex=${modeIndex}`)
             } else{
                if(!currentMode.machineChoose) {
                      handleTips('不能机选')
                  } else {
                      addMachineArr(currentMode)
                      if (label == '组选3-单式') {
                        deleteNumber(betArrLen - 1)
                      }
                      initPourAndTimes()
                  }
             }

          } else {
             handleTips(`只能选择一组号码`, multiPours)
          }
      }
      // 操作提示文字
      function handleTips(text, addJudge) {
        if (addJudge) {
          toast(`${currentMode.label}多${state.pours > 1 ? '注' : '倍'}只能选择一${state.pours > 1 ? '倍' : '注'}`)
        } else {
          toast(`${currentMode.label}${text}`)
        }
      }
      // 生成短信
      function genMessages() {
        console.log(`gen`)
        if (getVisitor() == 'true') {
         jumpTo(`/mine?author=true`) 
        } else {
          if (state.times > maxDoubleValue) {
            return
          } else if(currentMode.betNumberArr && currentMode.betNumberArr.length < 1){
            handleTips('请至少选择1注号码')
            return
          } else if(currentMode.betNumberArr && currentMode.betNumberArr.length > 5){
            handleTips('最多只能选择5注')
            return
          } else if(!currentMode.multiPours && currentMode.betNumberArr && currentMode.betNumberArr.length > 1) {
            handleTips('只能选择一组号码')
          } else if(!currentMode.timesNeeded && (!multiTimes || currentMode.betNumberArr.length > 1) && state.times > 1){
            handleTips('多注只能选择一倍')
          } else {
            // console.log(genePourNumber(currentMode, state.times))
            sendMessage(genePourNumber(currentMode, state.times), countMoney.value)
            // clearMode(mode, false, modeIndex)
            jumpTo(`/betSuccess?id=${gameId}`)
          }
        }
      }
      return {
        ...toRefs(state),
        label,
        betBall,
        countMoney,
        inputEvent,
        deleteNumber,
        modifyNumber,
        handleEvent,
        poursHandle,
        genMessages
      }
    }
}
</script>

<style lang=scss>
/* @import '../../style/index.scss'; */
.red {
  color: $theme-light;
  margin-left: 10px;
}
.blue {
  color: $blue-light;
  margin-left: 10px;
}
.number_recard {
  &_handles {
    display: flex;
    justify-content: space-between;
    padding: 20px $margin-big $margin-big $margin-big;
    &_item {
       border: solid #ccc 1px;
       background: #fff;
       width: 48%;
       text-align: center;
       border-radius: $box-radius-small;
       padding: 10px 0;
       &_label {
         &::before{
           content: '';
           display: inline-block;
           margin-right: 3px;
           width: 15px;
           height: 12px;
           background: url('../assets/imgs/plus_red.png') center center no-repeat;
           background-size: contain;
         }
       }
    }
  }
  &_betNumbers {
    position: relative;
    box-sizing: border-box;
    margin: $margin-big;
    padding: $margin-big;
    font-size: $font-middle;
    .lottery_modify {
        position: absolute;
        right: 0px;
        color: $gray-light;
        top: 14px;
    }
    .lottery_delete {
        position: absolute;
        left: -25px;
        background: url('../assets/imgs/lottery_list_delete.png') center center no-repeat;
        background-size: 15px;
        height: 15px;
        width: 15px;
        top: 17px;
    }
    .balls_content {
      max-width: 80%;
      display: flex;
      flex-wrap: wrap;
    }
    &::before {
      content: '';
      position: absolute;
      height: 40px;
      top: -20px;
      left: 0px;
      right: 0px;
      background: url('../assets/imgs/buy_top.png') center center no-repeat;
      background-size: contain;
    }
    &::after {
      content: '';
      /* background: url('../assets/imgs/buy_bot.png') center center no-repeat; */
      background-size: cover;
      position: absolute;
      left: 10px;
      right: 10px;
      height: 40px;
    }
    &_items {
       background: #fff;
       padding: 15px 10px 15px 35px;
       font-size: $font-middle;
       &_item {
         border-bottom: dashed #efefef 1px;
         display: flex;
         align-items: baseline;
         padding: 15px 0;
         position: relative;
         &:last-child{
            border-bottom: none;
          }
       }
       
    }
  }
  &_confirm {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $margin-big;
    background: #fff;
    &_infos{
      display: flex;
      justify-content: space-between;
      border-bottom: solid #efefef 1px;
      padding-bottom: 10px;
      font-size: $font-big;
      &_left {
        display: flex;
        align-items: center;
        input {
          width: 50px;
          border-left: solid #efefef 1px;
          border-right: solid #efefef 1px;
          text-align: center;
          padding: 2px 0;
          height: 20px;
        }
        &_icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          /* border: solid #efefef 1px; */
          padding: 3px;
        }
        &>div {
          display: flex;
          justify-content: center;
          align-items: center;
          border:solid #efefef 1px ;
          margin: 0 10px;
        }
        .icon_minus {
          background: url('../assets/imgs/minus_gray.png') center center no-repeat;
          background-size: 60%;
          border-right: none;
        }
        .icon_plus {
          background: url('../assets/imgs/plus_gray.png') center center no-repeat;
          background-size: 60%;
          border-left: none;
        }
      }
    }
    &_genmessage {
        display: flex;
        justify-content: space-between;
        align-content: baseline;
        align-items: baseline;
        padding-top: 10px;
      }
  }
  :deep .button_middle {
      padding: 10px 0;
    }
}
</style>
